<template>
  <div>
    <!-- Basic Details -->
    <basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></basic-details>
    <b-row class="">
      <b-col cols="4">
        <b-form-group label="Hearing Date" label-for="hearing-datepicker">
          <b-form-datepicker id="hearing-datepicker" v-model="HearingDate" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Case Status" label-for="case-status">
          <v-select
            v-model="SelectedStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="StatusOptions"
            id="case-status"
            class="case-status-select cursor-pointer select-size-md"
            placeholder="Status"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="align-text-bottom mb-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="ml-0 vh-60 btn-sm"
        :disabled="HearingDate ? false : true"
        @click="updateMatter"
      >
        Update
      </b-button>
    </div>
    <div>
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>
    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'listing_history'
        )
      "
    >
      <div
        v-if="$store.state.matterStore.currentmatter.listing_history !== null"
      >
        <section
          v-if="$store.state.matterStore.currentmatter.listing_history.length"
        >
          <h3 class="py-1">Listing History</h3>
          <b-card no-body>
            <!-- toggle button -->
            <b-table
              responsive="sm"
              :items="$store.state.matterStore.currentmatter.listing_history"
            />
          </b-card>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BFormGroup,
  BCard,
  BTable
} from 'bootstrap-vue'
// Section Imports
import BasicDetails from './components/basic-details/BasicDetails.vue'
import Ripple from 'vue-ripple-directive'
import ListingDetails from './components/listing-history/ListingDetails.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
    BFormGroup,
    BCard,
    BTable,
    // Basic Details
    BasicDetails,
    ListingDetails,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      HearingDate: this.$store.state.matterStore.currentmatter.hearing_date,
      SelectedStatus: this.$store.state.matterStore.currentmatter.case_status,
      StatusOptions: ['PENDING', 'DISPOSED']
    }
  },
  methods: {
    updateMatter() {
      const id = this.$store.state.matterStore.currentmatter._id
      console.log('Matter Id --> ', id)
      this.$store.dispatch('matterStore/updateOtherMatter', {
        ctx: this,
        id: id,
        payload: {
          hearing_date: this.HearingDate,
          case_status: this.SelectedStatus
        }
      })
    }
  }
}
</script>
