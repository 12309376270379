<template>
  <section class="matter-preview-wrapper">
    <b-row
      class="matter-preview"
      v-if="
        $store.state.matterStore.currentmatter &&
        !$store.state.matterStore.matterPreviewLoading
      "
    >
      <!-- Col: Left (Matter Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card>
          <!-- Action Buttons -->
          <b-row class="justify-content-end px-1 d-md-none">
            <b-row>
              <matter-notes-docs
                class="ml-75 mb-1"
                :data="$store.state.matterStore.currentmatter"
                :type="'sm'"
              />
            </b-row>
          </b-row>
          <other-details
            v-if="
              $store.state.matterStore.currentmatter.hc_name.includes('oc-')
            "
          ></other-details>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="matter-actions">
        <b-card>
          <client-button
            :data="$store.state.matterStore.currentmatter"
          ></client-button>
          <!-- Button: Docs -->
          <matter-notes-docs :data="$store.state.matterStore.currentmatter" />

          <matter-invoice
            class="mb-1"
            :data="$store.state.matterStore.currentmatter"
          />

          <!-- Button: Delete -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            block
            @click="deleteAlert($store.state.matterStore.currentmatter._id)"
          >
            Delete Matter
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      v-if="
        !$store.state.matterStore.currentmatter &&
        $store.state.matterStore.matterPreviewLoading
      "
      class="text-center mt-2"
    >
      <div class="d-flex justify-content-center my-2">
        <b-spinner label="Loading..." />
      </div>
    </b-card>

    <b-card
      v-if="
        !$store.state.matterStore.currentmatter &&
        !$store.state.matterStore.matterPreviewLoading
      "
      class="text-center mt-2"
    >
      Please Try Again!
    </b-card>
  </section>
</template>

<script>
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BButtonGroup,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BSpinner,
  BLink,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import MatterOrderJudgment from './judgment/MatterOrderJudgment.vue'
import MatterInvoice from './invoice/MatterInvoice.vue'
import MatterNotesDocs from './notesDocs/MatterNotesDocs.vue'
import ClientButton from './components/ClientButton.vue'
// Types
import OtherDetails from './OtherDetails.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BButtonGroup,
    BOverlay,

    Logo,
    MatterOrderJudgment,
    MatterInvoice,
    MatterNotesDocs,
    ClientButton,
    OtherDetails
  },
  methods: {
    // delete alert
    deleteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this matter',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('matterStore/deleteMatter', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              // this.refetchData()
            })
        }
      })
    }
  },
  watch: {
    '$route.params.id'(id) {
      console.log('Matter Id --> ', id)
      this.$store.dispatch('matterStore/fetchMatter', { id: id })
    }
  },
  beforeCreate() {
    const id = router.currentRoute.params.id
    console.log('Matter Id --> ', id)
    this.$store.dispatch('matterStore/fetchMatter', { id: id })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Matter Specific Styles
  .matter-preview-wrapper {
    .row.matter-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .matter-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 80% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .matter-actions {
      display: none;
    }
  }
}
</style>
