var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"matter-preview-wrapper"},[(
      _vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-row',{staticClass:"matter-preview"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',{staticClass:"justify-content-end px-1 d-md-none"},[_c('b-row',[_c('matter-notes-docs',{staticClass:"ml-75 mb-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter,"type":'sm'}})],1)],1),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('oc-')
          )?_c('other-details'):_vm._e()],1)],1),_c('b-col',{staticClass:"matter-actions",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('client-button',{attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('matter-notes-docs',{attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('matter-invoice',{staticClass:"mb-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"danger","block":""},on:{"click":function($event){return _vm.deleteAlert(_vm.$store.state.matterStore.currentmatter._id)}}},[_vm._v(" Delete Matter ")])],1)],1)],1):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      _vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_vm._v(" Please Try Again! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }